a {
	text-decoration: none;
	color: inherit;
}
a:hover {
	text-decoration: none;
}

p a {
	text-decoration: underline;
}
